import React from 'react'

function Footer() {
  return (
    <>
        <footer className='bg-light text-center text-dark p-2'>

        copyright &#169; Restaurantly.All rights Reserved <br></br>
        <i>designed by <strike><b>DELICI0US</b></strike> 2023</i>

        </footer>
    </>
  )
}

export default Footer